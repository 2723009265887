import Cents from 'goodeggs-money';

import {ClientMarketOrder} from 'domain/orders/client_types';
import {PromoCodeType} from 'domain/promotions/types';
import {ClientUser} from 'domain/users/client_types';

export interface CheckoutEvent {
  checkoutType: 'add' | 'place' | 'edit';
  addonValue?: number;
  daysBeforeFulfillment?: number;
}

// @TODO cleanup with typewriter migration

export interface CheckoutEventData extends CheckoutEvent {
  addingToOrder: boolean;
  credits: number;
  delivery: number;
  deliveryDay: string;
  deliveryDetails: {
    address: string;
    address2?: string | null;
    city: string;
    state: string;
    zip: string;
    lat?: number | null;
    lon?: number | null;
    isGift: boolean;
    canLeaveAtDoor: boolean;
    giftRecipientName?: string | null;
    giftRecipientPhone?: string | null;
    deliveryInstructions?: string | null;
    giftMessage?: string | null;
  };
  deliveryEndWindow: string;
  deliveryStartWindow: string;
  discount: number;
  experiments: Record<string, any>;
  fees: number;
  itemCount: number;
  loggedIn: boolean;
  masquerading: boolean;
  orderCount: number;
  orderCutoff: string;
  orderId: string;
  pageName: string;
  platform: string;
  productCount: number;
  products: Array<{id: string; name: string; price: number; quantity: number}>;
  promoCodes: Array<{
    code: string;
    type: PromoCodeType;
    label?: string;
    value: number;
  }>;
  referralDiscount: number;
  revenue: number;
  shoppingSession?: Record<string, any>;
  subtotal: number;
  subtotalAfter: number;
  subtotalBefore: number;
  total: number;
  tax: number;
  totalCheckoutValue: number;
  userId: string;
  isMembershipOrder?: boolean;
}

export type CheckoutTrackingParams = Partial<
  Pick<
    CheckoutEventData,
    | 'addonValue'
    | 'addingToOrder'
    | 'loggedIn'
    | 'masquerading'
    | 'pageName'
    | 'subtotalAfter'
    | 'subtotalBefore'
  >
> &
  Pick<CheckoutEventData, 'checkoutType'>;

function getCheckoutEventData(
  order: ClientMarketOrder,
  user: ClientUser,
  data: CheckoutTrackingParams,
): CheckoutEventData {
  return {
    addingToOrder: data.addingToOrder ?? false,
    addonValue: data.addonValue ?? 0,
    checkoutType: data.checkoutType,
    credits: order.totalsForTracking.credits,
    delivery: order.totalsForTracking.delivery,
    deliveryDay: new Date(order.deliveryWindow.startAt).toDateString(),
    deliveryDetails: order.deliveryDetails,
    deliveryEndWindow: order.deliveryWindow.endAt,
    deliveryStartWindow: order.deliveryWindow.startAt,
    discount: order.totalsForTracking.discount,
    experiments: window.metrics.pageProperties?.experiments,
    fees: order.totalsForTracking.fees,
    itemCount: order.items.reduce((acc, item) => acc + item.quantity, 0),
    loggedIn: data.loggedIn ?? false,
    masquerading: data.masquerading ?? false,
    orderCount: user.orderCount,
    orderCutoff: order.orderCutoff,
    orderId: order.id,
    pageName: data.pageName ?? '',
    platform: 'web',
    productCount: order.items.length,
    products: order.items.map((item) => {
      return {
        id: item.product.id,
        name: item.product.name,
        price: new Cents(item.subtotal).toDollars(),
        quantity: item.quantity,
      };
    }),
    promoCodes: order.promoCodes.map((promoCode) => ({
      code: promoCode.code,
      label: promoCode.label,
      type: promoCode.type,
      value: promoCode.value,
    })),
    referralDiscount: order.totalsForTracking.referralDiscount,
    revenue: order.totalsForTracking.revenue,
    shoppingSession: window.metrics.pageProperties?.shoppingSession,
    subtotal: order.totalsForTracking.subtotal,
    subtotalAfter: data.subtotalAfter ?? order.totalsForTracking.subtotal,
    subtotalBefore: data.subtotalBefore ?? order.totalsForTracking.subtotal,
    total: order.totalsForTracking.total,
    tax: order.totalsForTracking.tax,
    totalCheckoutValue: order.totalsForTracking.totalCheckoutValue,
    userId: user.id,
    isMembershipOrder: order.isMembershipOrder ?? false,
  };
}

export function trackCheckoutCompleted(order: ClientMarketOrder, user: ClientUser): void {
  const data = getCheckoutEventData(order, user, {
    checkoutType: 'place',
  });
  window.metrics.track('Checkout Completed', data);
}

export function trackCheckoutUpdated(
  order: ClientMarketOrder,
  user: ClientUser,
  checkoutEvent: CheckoutEvent,
  subtotalBefore?: number,
): void {
  const data = getCheckoutEventData(order, user, {
    ...checkoutEvent,
    addingToOrder: checkoutEvent.checkoutType === 'add',
    subtotalBefore: subtotalBefore ?? order.totalsForTracking.subtotal,
  });
  window.metrics.track('Checkout Updated', data);
}
