import React, {ReactElement, ReactNode} from 'react';

const Masked = ({children}: {children?: ReactNode}): ReactElement => (
  <div className="masked" data-testid="masked">
    <div className="masked__overlay" />
    <div className="masked__hero">{children}</div>
  </div>
);

export default Masked;
