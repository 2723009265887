import {postUpdatePromoCode} from '../helpers/api_client';

const START_UPDATE_PROMO_CODE = 'ORDER_START_UPDATE_PROMO_CODE';
const CANCEL_UPDATE_PROMO_CODE = 'ORDER_CANCEL_UPDATE_PROMO_CODE';
const SAVING_PROMO_CODE = 'ORDER_SAVING_PROMO_CODE';
const FINISH_SAVING_PROMO_CODE = 'ORDER_FINISH_SAVING_PROMO_CODE';
const ERROR_SAVING_PROMO_CODE = 'ORDER_ERROR_SAVING_PROMO_CODE';

export const actions = {
  startUpdatePromoCode: () => ({type: START_UPDATE_PROMO_CODE}),
  cancelUpdatePromoCode: () => ({type: CANCEL_UPDATE_PROMO_CODE}),
  savePromoCode: (promoCode) => (dispatch, getState) => {
    dispatch({
      type: SAVING_PROMO_CODE,
    });
    const {order} = getState();
    const promise = postUpdatePromoCode(order.id, promoCode)
      .then((data) => {
        dispatch({
          type: FINISH_SAVING_PROMO_CODE,
          order: data,
        });
      })
      .catch((error) => {
        dispatch({
          type: ERROR_SAVING_PROMO_CODE,
          error,
        });
      });

    return promise;
  },
};

export function reducer(state = {}, action = {}) {
  let {order} = state;
  const paymentDetails = state.paymentDetails || {
    isEditing: false,
    isSaving: false,
  };
  paymentDetails.error = null;

  switch (action.type) {
    case SAVING_PROMO_CODE: {
      paymentDetails.isSaving = true;
      break;
    }
    case START_UPDATE_PROMO_CODE: {
      paymentDetails.isEditing = true;
      break;
    }
    case CANCEL_UPDATE_PROMO_CODE: {
      paymentDetails.isEditing = false;
      break;
    }
    case FINISH_SAVING_PROMO_CODE: {
      order = action.order;
      paymentDetails.isEditing = false;
      paymentDetails.isSaving = false;
      break;
    }
    case ERROR_SAVING_PROMO_CODE: {
      paymentDetails.isSaving = false;
      paymentDetails.error = action.error;
      break;
    }
    default: {
      // noop
    }
  }

  return {...state, order, paymentDetails};
}
