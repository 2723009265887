import _ from 'lodash';

import {trackCheckoutUpdated} from 'web/helpers/checkout_metrics';

import {postDeliveryTimeChange} from '../helpers/api_client';

const START_EDIT_TIME = 'ORDER_START_EDIT_TIME';
const CANCEL_EDIT_TIME = 'ORDER_CANCEL_EDIT_TIME';
const SELECT_TIME = 'ORDER_SELECT_TIME';
const SAVING_EDIT_TIME = 'ORDER_SAVING_EDIT_TIME';
const FINISH_SAVING_EDIT_TIME = 'ORDER_FINISH_SAVING_EDIT_TIME';
const ERROR_SAVING_EDIT_TIME = 'ORDER_ERROR_SAVING_EDIT_TIME';

export const actions = {
  startEditTime: () => ({
    type: START_EDIT_TIME,
  }),
  selectTime: (fulfillmentOffer) => ({
    type: SELECT_TIME,
    fulfillmentOffer,
  }),
  cancelEditTime: () => ({
    type: CANCEL_EDIT_TIME,
  }),
  saveEditTime: () => (dispatch, getState) => {
    dispatch({
      type: SAVING_EDIT_TIME,
    });
    const {deliveryTime, order, user} = getState();
    const {selectedFulfillmentOffer} = deliveryTime;
    const promise = postDeliveryTimeChange(order.id, selectedFulfillmentOffer)
      .then((data) => {
        dispatch({
          type: FINISH_SAVING_EDIT_TIME,
          order: data,
        });
        trackCheckoutUpdated(data, user, {checkoutType: 'edit'});
      })
      .catch((error) => {
        dispatch({
          type: ERROR_SAVING_EDIT_TIME,
          error,
        });
      });

    return promise;
  },
};

function reduceDeliveryTime(deliveryTime, order, action = {}) {
  switch (action.type) {
    case START_EDIT_TIME:
      return _.assignIn({}, deliveryTime, {isEditing: true});
    case SELECT_TIME: {
      const updatedFulfillmentOffer = {selectedFulfillmentOffer: action.fulfillmentOffer};
      return _.assignIn({}, deliveryTime, updatedFulfillmentOffer);
    }
    case CANCEL_EDIT_TIME:
      return _.assignIn({}, deliveryTime, {
        isEditing: false,
        error: null,
      });
    case SAVING_EDIT_TIME:
      return _.assignIn({}, deliveryTime, {isSaving: true});
    case FINISH_SAVING_EDIT_TIME:
      return _.assignIn({}, deliveryTime, {
        isSaving: false,
        isEditing: false,
        error: null,
      });
    case ERROR_SAVING_EDIT_TIME:
      return _.assignIn({}, deliveryTime, {
        isSaving: false,
        error: action.error,
      });
    default:
      return deliveryTime;
  }
}

export function reducer(state = {}, action = {}) {
  let {order} = state;
  let deliveryTime = state.deliveryTime || {
    isEditing: false,
    isSaving: false,
  };

  switch (action.type) {
    case START_EDIT_TIME: {
      const selectedFulfillmentOption = _.find(state.fulfillmentOptions, ({offer}) =>
        _.isEqual(
          _.pick(offer, 'startAt', 'endAt'),
          _.pick(state.order.deliveryWindow, 'startAt', 'endAt'),
        ),
      );
      const selectedFulfillmentOffer = selectedFulfillmentOption
        ? selectedFulfillmentOption.offer
        : null;
      // Reset dropdown state
      deliveryTime = _.assignIn({}, deliveryTime, {selectedFulfillmentOffer});
      break;
    }
    case FINISH_SAVING_EDIT_TIME: {
      order = action.order;
      break;
    }
    case ERROR_SAVING_EDIT_TIME: {
      deliveryTime = _.assignIn({}, deliveryTime, {error: action.error});
      break;
    }
    default: {
      // noop
    }
  }

  return _.assignIn({}, state, {
    deliveryTime: reduceDeliveryTime(deliveryTime, order, action),
    order,
  });
}
