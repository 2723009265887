import {postJson} from 'web/helpers/api_helpers';

export function postOrderItemChanges(id, changes) {
  return postJson(`/api/account/orders/${id}/update-quantities`, {
    body: changes,
  });
}

export function postCancelOrder(id, {afterCutoffOverride = false}) {
  return postJson(`/api/account/orders/${id}`, {body: {afterCutoffOverride}});
}

export function postDeliveryTimeChange(id, fulfillmentOffer) {
  return postJson(`/api/account/orders/${id}/update-fulfillment`, {
    body: {fulfillmentOffer},
  });
}

export function postDeliveryAddressChange(id, deliveryDetails) {
  return postJson(`/api/account/orders/${id}/update-fulfillment`, {
    body: {deliveryDetails},
  });
}

export function postUpdatePromoCode(id, promoCode) {
  return postJson(`/api/account/orders/${id}/update-promo-code`, {
    body: {promoCode},
  });
}
