import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {formatCreditCard} from 'goodeggs-formatters';

import {formatPromoCode} from 'infra/formatters/money';
import Alert from 'web/components/alert';
import Masked from 'web/components/masked';
import SubmitButton from 'web/components/submit_button';

import {actions} from '../../ducks/payment_details';

class PaymentDetails extends Component {
  constructor() {
    super();
    this.state = {
      newPromoCode: '',
    };
  }

  handleSaveAddPromoCode(e) {
    e.preventDefault();
    if (this.state.newPromoCode.length) {
      this.props.handleSavePromoCode(this.state.newPromoCode);
    } else {
      this.props.handleCancelAddPromoCode();
    }
  }

  handleChange(event) {
    this.setState({newPromoCode: event.target.value});
  }

  renderPromoCodes() {
    return (
      <div>
        {this.props.promoCodes.map(function (promoCode, i) {
          return (
            <div className="promo" key={`promo-${i}`}>
              {formatPromoCode(promoCode)}
            </div>
          );
        })}
        <a
          className="single-order-page__payment-details__add-promo"
          onClick={this.props.handleStartAddPromoCode}
        >
          Add a Promo/School Code
        </a>
      </div>
    );
  }

  renderPaymentDetails() {
    const showPromoCodes =
      this.props.user.masquerading || (this.props.promoCodes && this.props.promoCodes.length);
    const showCreditCard = this.props.creditCard && this.props.creditCard.type;
    if ((!showPromoCodes && !showCreditCard) || !this.props.user.masquerading) {
      return null;
    }
    return (
      <div className="single-order-page__section single-order-page__payment-details">
        <div className="single-order-page__section-heading">Payment Details</div>
        {this.props.error ? (
          <div className="single-order-page__delivery-time-error">
            <Alert
              type="error"
              heading={this.props.error.customerMessage || 'Sorry, that promo code is invalid.'}
            />
          </div>
        ) : null}
        {showCreditCard ? (
          <div>
            <label className="single-order-page__payment-label">Current Card</label>
            <div>{formatCreditCard(this.props.creditCard)}</div>
          </div>
        ) : null}
        {showPromoCodes ? (
          <div>
            <label className="single-order-page__payment-label">Promo Code</label>
            {this.props.isEditing ? (
              <div>
                <form onSubmit={this.handleSaveAddPromoCode.bind(this)}>
                  <input
                    placeholder="Enter your code"
                    className="form-control"
                    value={this.state.newPromoCode}
                    onChange={this.handleChange.bind(this)}
                  />
                  <SubmitButton
                    isSaving={this.props.isSaving}
                    workingLabel="saving"
                    className="single-order-page__save-button"
                    onClick={this.handleSaveAddPromoCode.bind(this)}
                  >
                    Save
                  </SubmitButton>
                  <button
                    className="single-order-page__cancel-button"
                    onClick={this.props.handleCancelAddPromoCode}
                  >
                    Cancel
                  </button>
                </form>
              </div>
            ) : (
              this.renderPromoCodes()
            )}
          </div>
        ) : null}
      </div>
    );
  }

  render() {
    if (this.props.isEditing) {
      return <Masked>{this.renderPaymentDetails()}</Masked>;
    }
    return this.renderPaymentDetails();
  }
}

PaymentDetails.propTypes = {
  creditCard: PropTypes.object.isRequired,
  error: PropTypes.object,
  handleCancelAddPromoCode: PropTypes.func,
  handleSavePromoCode: PropTypes.func,
  handleStartAddPromoCode: PropTypes.func,
  isEditing: PropTypes.bool,
  isSaving: PropTypes.bool,
  promoCodes: PropTypes.array,
  user: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    creditCard: state.user.active_card,
    error: state.paymentDetails.error,
    isEditing: state.paymentDetails.isEditing,
    isWorking: state.paymentDetails.isWorking,
    promoCodes: state.order.promoCodes,
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleStartAddPromoCode: () => dispatch(actions.startUpdatePromoCode()),
    handleCancelAddPromoCode: () => dispatch(actions.cancelUpdatePromoCode()),
    handleSavePromoCode: (promoCode) => dispatch(actions.savePromoCode(promoCode)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentDetails);
