import React, {Component} from 'react';
import classNames from 'classnames';
import {connect} from 'react-redux';
import {isDirty, isValid} from 'redux-form';

import Masked from 'web/components/masked';
import DeliveryAddressForm from 'web/components/delivery_address_form';

import EditControls from '../edit_controls';
import {actions} from '../../ducks/delivery_address';

function mapStateToProps(state) {
  const {deliveryDetails} = state.order;

  return {
    error: state.deliveryAddress.error,
    canSave:
      state.deliveryAddress.isEditing &&
      isDirty('deliveryAddress')(state) &&
      isValid('deliveryAddress')(state),
    isAttendedDeliveryRequired: state.order.isAttendedDeliveryRequired,
    deliveryDetails,
    hasChanges: state.deliveryAddress.isEditing && isDirty('deliveryAddress')(state),
    isEditable: state.order.isEditable,
    isEditing: state.deliveryAddress.isEditing,
    isGift: state.order.isGift,
    isSaving: state.deliveryAddress.isSaving,
  };
}

class DeliveryAddressContainer extends Component {
  render() {
    return (
      <div className="single-order-page__section single-order-page__address">
        {this.props.isEditable ? (
          <EditControls
            isEditing={this.props.isEditing}
            isSaving={this.props.isSaving}
            hasChanges={this.props.hasChanges}
            canSave={this.props.canSave}
            onChange={this.props.onChange}
            onSave={this.props.onSave}
            onCancel={this.props.onCancel}
          />
        ) : null}
        <div className="single-order-page__section-heading">
          {this.props.isGift ? 'Gift Recipient' : 'Delivery Address'}
        </div>
        {this.props.children}
      </div>
    );
  }
}

class DeliveryAddress extends Component {
  handleChangeClicked() {
    this.props.dispatch(actions.startEditAddress());
  }

  handleCancelClicked() {
    this.props.dispatch(actions.cancelEditAddress());
  }

  handleSaveClicked() {
    if (!this.props.canSave) {
      this.handleCancelClicked();
      return;
    }
    this.props.dispatch(actions.saveEditAddress());
  }

  renderStaticAddress() {
    return (
      <div className="single-order-page__address-body">
        {this.props.isGift ? (
          <div className="single-order-page__gift-recipient">
            {this.props.deliveryDetails.giftRecipientName}
          </div>
        ) : null}
        <div className="single-order-page__address-line-item">
          {this.props.deliveryDetails.address}
        </div>
        {this.props.deliveryDetails.address2 ? (
          <div className="single-order-page__address-line-item">
            {this.props.deliveryDetails.address2}
          </div>
        ) : null}
        <div className="single-order-page__address-line-item">
          {`${this.props.deliveryDetails.city}, ${this.props.deliveryDetails.state}, ${this.props.deliveryDetails.zip}`}
        </div>
        {this.props.isGift ? (
          <div className="single-order-page__gift-recipient-phone">
            {this.props.deliveryDetails.giftRecipientPhone}
          </div>
        ) : null}
        {this.props.isAttendedDeliveryRequired ? (
          <div className="delivery-address-form__leave-at-door-warning">
            <i
              className={classNames(
                'icon',
                'delivery-address-form__orange-icon',
                'icon-circle-info',
              )}
            />
            <span>ID required in person for alcohol delivery</span>
          </div>
        ) : (
          <div className="single-order-page__address-leave-at-door">
            <i className="icon icon-circle-checkmark single-order-page__green-icon" />
            {this.props.deliveryDetails.canLeaveAtDoor ? (
              <span> {"It's ok to leave the order unattended"} </span>
            ) : (
              <span> {"Don't leave the order unattended"} </span>
            )}
          </div>
        )}
        {this.props.deliveryDetails.deliveryInstructions ? (
          <div className="single-order-page__address-delivery-instructions">
            {this.props.deliveryDetails.deliveryInstructions}
          </div>
        ) : null}
        {this.props.isGift &&
        this.props.deliveryDetails.giftMessage &&
        this.props.deliveryDetails.giftMessage.length > 0
          ? [
              <div key="gift-message-header" className="single-order-page__gift-message-heading">
                Gift Message
              </div>,
              <div key="gift-message" className="single-order-page__gift-message">
                {this.props.deliveryDetails.giftMessage}
              </div>,
            ]
          : null}
      </div>
    );
  }

  render() {
    if (this.props.isEditing) {
      return (
        <Masked>
          <DeliveryAddressContainer
            {...this.props}
            onChange={this.handleChangeClicked.bind(this)}
            onSave={this.handleSaveClicked.bind(this)}
            onCancel={this.handleCancelClicked.bind(this)}
          >
            <DeliveryAddressForm
              isAttendedDeliveryRequired={this.props.isAttendedDeliveryRequired}
              initialValues={this.props.deliveryDetails}
              apiError={this.props.error}
              isGift={this.props.isGift}
            />
          </DeliveryAddressContainer>
        </Masked>
      );
    }
    return (
      <DeliveryAddressContainer
        {...this.props}
        onChange={this.handleChangeClicked.bind(this)}
        onSave={this.handleSaveClicked.bind(this)}
        onCancel={this.handleCancelClicked.bind(this)}
      >
        {this.renderStaticAddress()}
      </DeliveryAddressContainer>
    );
  }
}

export default connect(mapStateToProps)(DeliveryAddress);
