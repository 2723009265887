import {getFormValues} from 'redux-form';

import {trackCheckoutUpdated} from 'web/helpers/checkout_metrics';

import {postDeliveryAddressChange} from '../helpers/api_client';

export const START_EDIT_ADDRESS = 'ORDER_START_EDIT_ADDRESS';
export const CANCEL_EDIT_ADDRESS = 'ORDER_CANCEL_EDIT_ADDRESS';
export const SAVING_EDIT_ADDRESS = 'ORDER_SAVING_EDIT_ADDRESS';
export const FINISH_SAVING_EDIT_ADDRESS = 'ORDER_FINISH_SAVING_EDIT_ADDRESS';
export const ERROR_SAVING_EDIT_ADDRESS = 'ORDER_ERROR_SAVING_EDIT_ADDRESS';

export const actions = {
  startEditAddress: () => ({
    type: START_EDIT_ADDRESS,
  }),
  cancelEditAddress: () => ({
    type: CANCEL_EDIT_ADDRESS,
  }),
  saveEditAddress: () => (dispatch, getState) => {
    dispatch({
      type: SAVING_EDIT_ADDRESS,
    });
    const state = getState();
    const {order, user} = state;
    const promise = postDeliveryAddressChange(order.id, getFormValues('deliveryAddress')(state))
      .then((data) => {
        dispatch({
          type: FINISH_SAVING_EDIT_ADDRESS,
          order: data,
        });
        trackCheckoutUpdated(data, user, {checkoutType: 'edit'});
      })
      .catch((error) => {
        dispatch({
          type: ERROR_SAVING_EDIT_ADDRESS,
          error,
        });
      });

    return promise;
  },
};

function reduceDeliveryAddress(deliveryAddress, action = {}) {
  switch (action.type) {
    case START_EDIT_ADDRESS: {
      const newState = {...deliveryAddress, isEditing: true};
      return newState;
    }
    case CANCEL_EDIT_ADDRESS: {
      if (deliveryAddress.isSaving) {
        return deliveryAddress;
      }
      return {...deliveryAddress, isEditing: false, error: null};
    }
    case SAVING_EDIT_ADDRESS: {
      return {...deliveryAddress, isSaving: true};
    }
    case FINISH_SAVING_EDIT_ADDRESS: {
      return {
        ...deliveryAddress,
        isSaving: false,
        isEditing: false,
        error: null,
      };
    }
    case ERROR_SAVING_EDIT_ADDRESS: {
      return {...deliveryAddress, isSaving: false};
    }
    default: {
      return deliveryAddress;
    }
  }
}

export function reducer(state = {}, action = {}) {
  let {order} = state;
  const {error} = state;

  let deliveryAddress = state.deliveryAddress || {};
  let newState = state;

  switch (action.type) {
    case START_EDIT_ADDRESS:
      deliveryAddress = {
        isEditing: false,
        isSaving: false,
        canSave: false,
        hasChanged: false,
        changeSet: newState.order.deliveryDetails,
      };
      break;
    case FINISH_SAVING_EDIT_ADDRESS:
      order = action.order;
      break;
    case ERROR_SAVING_EDIT_ADDRESS:
      deliveryAddress = {...deliveryAddress, error: action.error};
      break;
    default:
    // noop
  }
  deliveryAddress = reduceDeliveryAddress(deliveryAddress, action);
  newState = {
    ...newState,
    deliveryAddress,
    order,
    error,
  };
  return newState;
}
