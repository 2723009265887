import {isEmpty, maxBy} from 'lodash';

import {trackCheckoutUpdated} from 'web/helpers/checkout_metrics';
import {trackProductAdded} from '@analytics/client/product';

import * as apiClient from '../helpers/api_client';

export const START_EDIT_ITEMS = 'ORDER_START_EDIT_ITEMS';
export const CANCEL_EDIT_ITEMS = 'ORDER_CANCEL_EDIT_ITEMS';
export const SAVING_EDIT_ITEMS = 'ORDER_SAVING_EDIT_ITEMS';
export const FINISH_SAVING_EDIT_ITEMS = 'ORDER_FINISH_SAVING_EDIT_ITEMS';
export const ERROR_SAVING_EDIT_ITEMS = 'ORDER_ERROR_SAVING_EDIT_ITEMS';
export const INCREMENT_ITEM = 'ORDER_INCREMENT_ITEM';
export const DECREMENT_ITEM = 'ORDER_DECREMENT_ITEM';
export const SET_SUBSCRIBE = 'ORDER_SET_SUBSCRIBE';
export const REMOVE_ITEM = 'ORDER_REMOVE_ITEM';

export const actions = {
  startEditItems: () => ({
    type: START_EDIT_ITEMS,
  }),
  cancelEditItems: () => ({
    type: CANCEL_EDIT_ITEMS,
  }),
  saveItemEdits: () => (dispatch, getState) => {
    dispatch({
      type: SAVING_EDIT_ITEMS,
    });
    const {orderItems, order, user} = getState();
    const edits = orderItems.edits || {};
    const eventPromises = [];
    const changes = order.items
      .filter((item) => item.id in edits)
      .map((item) => {
        const productId = item.product.id;
        if (edits[item.id].quantityDifference > 0) {
          eventPromises.push(
            trackProductAdded({
              productId: item.product.id,
              quantity: edits[item.id].quantityDifference,
              state: {
                ...getState(),
                products: {
                  [item.product.id]: {
                    ...item.product,
                    id: item.product.id,
                    currentProducer: item.product.vendor,
                    retailPrice: item.subtotal / item.quantity,
                  },
                },
              },
            }),
          );
        }
        return {
          productId,
          subscriptionItemId: item.subscriptionItemId,
          quantity: item.quantity + (edits[item.id].quantityDifference || 0),
          subscribe: edits[item.id].subscribe,
        };
      });
    const promise = apiClient.postOrderItemChanges(order.id, changes).then(
      async ({order: updatedOrder, fulfillmentOptions}) => {
        await Promise.all(eventPromises);
        dispatch({
          type: FINISH_SAVING_EDIT_ITEMS,
          order: updatedOrder,
          fulfillmentOptions,
        });
        trackCheckoutUpdated(updatedOrder, user, {checkoutType: 'edit'});
      },
      (error) => {
        dispatch({
          type: ERROR_SAVING_EDIT_ITEMS,
          error,
        });
      },
    );

    return promise;
  },
  incrementItem: (itemId) => ({
    type: INCREMENT_ITEM,
    itemId,
  }),
  decrementItem: (itemId) => ({
    type: DECREMENT_ITEM,
    itemId,
  }),
  setSubscribe: (itemId, subscribe) => ({
    type: SET_SUBSCRIBE,
    itemId,
    subscribe,
  }),
  removeItem: (itemId) => ({
    type: REMOVE_ITEM,
    itemId,
  }),
};

// nested reducer for order items component state management
// requires order items array (immutable page-level state)
function getOrderItemsReducer(orderItems = []) {
  return (state = {}, action = {}) => {
    let edits, quantity;
    switch (action.type) {
      case FINISH_SAVING_EDIT_ITEMS:
        return {
          ...state,
          edits: {},
          isSaving: false,
          isEditing: false,
          error: null,
        };
      case ERROR_SAVING_EDIT_ITEMS:
        return {...state, isSaving: false, error: action.error};
      default:
        // ignore all other actions if saving
        if (state.isSaving) return state;
    }

    switch (action.type) {
      case START_EDIT_ITEMS:
        return {...state, isEditing: true};
      case CANCEL_EDIT_ITEMS:
        return {...state, edits: {}, isEditing: false, error: null};
      case SAVING_EDIT_ITEMS:
        if (!state.isEditing) return state;
        return {...state, isSaving: true};
      case SET_SUBSCRIBE: {
        const item = orderItems.find((obj) => obj.id === action.itemId);
        if (!state.isEditing || !item.canSubscribe) return state;
        edits = {...state.edits};
        if (edits[action.itemId]) edits[action.itemId].subscribe = action.subscribe;
        else edits[action.itemId] = {subscribe: action.subscribe};

        // do nothing if unsubscribing from unsubscribed item or subscribing to subscribed item
        if (Boolean(item.subscriptionItemId) === action.subscribe) {
          delete edits[action.itemId].subscribe;
          if (isEmpty(edits[action.itemId])) {
            delete edits[action.itemId];
          }
        }
        return {...state, edits};
      }
      case INCREMENT_ITEM: {
        edits = {...state.edits};
        if (edits[action.itemId] == null) {
          edits[action.itemId] = {quantityDifference: 0};
        } else if (edits[action.itemId].quantityDifference == null) {
          edits[action.itemId].quantityDifference = 0;
        }
        edits[action.itemId].quantityDifference += 1;
        const item = orderItems.find((obj) => obj.id === action.itemId);
        if (!state.isEditing || !item.isQuantityEditable) {
          return state;
        }

        const maxQuantity = maxBy(
          item.product.quantityOptions,
          (option) => option.quantity,
        ).quantity;
        // do not let original quantity + edits go above maxQuantity
        edits[action.itemId].quantityDifference = Math.min(
          maxQuantity - item.quantity,
          edits[action.itemId].quantityDifference,
        );
        if (edits[action.itemId].quantityDifference === 0) {
          delete edits[action.itemId].quantityDifference;
          if (isEmpty(edits[action.itemId])) {
            delete edits[action.itemId];
          }
        }
        return {...state, edits};
      }
      case DECREMENT_ITEM: {
        edits = {...state.edits};
        if (edits[action.itemId] == null) {
          edits[action.itemId] = {quantityDifference: 0};
        } else if (edits[action.itemId].quantityDifference == null) {
          edits[action.itemId].quantityDifference = 0;
        }
        edits[action.itemId].quantityDifference -= 1;

        const item = orderItems.find((obj) => obj.id === action.itemId);
        if (!state.isEditing || !item.isQuantityEditable) {
          return state;
        }
        // do not allow original quantity + edits go below zero
        edits[action.itemId].quantityDifference = Math.max(
          -item.quantity,
          edits[action.itemId].quantityDifference,
        );

        if (edits[action.itemId].quantityDifference === 0) {
          delete edits[action.itemId].quantityDifference;
          if (isEmpty(edits[action.itemId])) {
            delete edits[action.itemId];
          }
        }
        return {...state, edits};
      }
      case REMOVE_ITEM: {
        edits = {...state.edits};
        quantity = orderItems.find((obj) => obj.id === action.itemId).quantity;
        edits[action.itemId] = {quantityDifference: -quantity};
        return {...state, edits};
      }
      default: {
        return state;
      }
    }
  };
}

export function reducer(state = {}, action = {}) {
  // most of the component-specific state is stored as {orderItems}
  const orderItems = getOrderItemsReducer(state.order.items)(state.orderItems, action);
  const newState = {...state, orderItems};

  // however, in one case we need to change toplevel store (updating the order)
  if (action.type === FINISH_SAVING_EDIT_ITEMS) {
    newState.order = action.order;
    newState.fulfillmentOptions = action.fulfillmentOptions;
  }

  return newState;
}
