import React, {Component, ReactElement} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {defaultError} from 'infra/formatters/error';
import Alert from 'web/components/alert';
import Modal from 'web/components/modal';
import SubmitButton from 'web/components/submit_button';

import {actions} from '../../ducks/cancel_order';

interface FormattedError {
  customerMessage: string;
  advice: string;
}
function formatError(error: {type: string}): FormattedError | null {
  if (error == null) {
    return null;
  }
  switch (error.type) {
    case 'FULFILLMENT_CUTOFF_PASSED':
      return {
        customerMessage: "Oops... it's too late change this order",
        advice: 'The order cutoff has passed.',
      };
    case 'FULFILLMENT_NOT_EDITABLE':
      return {
        customerMessage: 'Oops... this order is no longer editable',
        advice: 'Please refresh this page.',
      };
    case 'UNAUTHORIZED_CANCEL_ORDER_REQUEST':
      return {
        customerMessage: "Oops... you don't have permission to cancel this order",
        advice: 'Please send Customer Care a message.',
      };
    default:
      return defaultError();
  }
}

export interface Props {
  confirmCancelOrder: () => void;
  formattedError?: FormattedError | null;
  isConfirming: boolean;
  isMasqueradingAfterCutoff: boolean;
  isSaving: boolean;
  saveCancelOrder: () => void;
  saveCancelOrderAfterCutoff: () => void;
  unconfirmCancelOrder: () => void;
}

// TODO(serhalp) Once `../ducks/cancel_order` is typed, import its State type here and remove this.
export interface State {
  cancelOrder: {
    error: {
      type: string;
    };
    isConfirming: boolean;
    isSaving: boolean;
  };
}

export class CancelOrder extends Component<Props> {
  public static propTypes = {
    confirmCancelOrder: PropTypes.func.isRequired,
    unconfirmCancelOrder: PropTypes.func.isRequired,
    saveCancelOrder: PropTypes.func.isRequired,
    saveCancelOrderAfterCutoff: PropTypes.func.isRequired,
    formattedError: PropTypes.shape({
      customerMessage: PropTypes.string.isRequired,
      advice: PropTypes.string.isRequired,
    }),
    isConfirming: PropTypes.bool.isRequired,
    isSaving: PropTypes.bool.isRequired,
    isMasqueradingAfterCutoff: PropTypes.bool.isRequired,
  };

  public render(): ReactElement {
    const {
      confirmCancelOrder,
      formattedError,
      isConfirming,
      isMasqueradingAfterCutoff,
      isSaving,
      saveCancelOrder,
      saveCancelOrderAfterCutoff,
      unconfirmCancelOrder,
    } = this.props;
    return (
      <div className="single-order-page__cancel">
        <span className="single-order-page__cancel__cancel-link" onClick={confirmCancelOrder}>
          Cancel Order
        </span>

        {isConfirming ? (
          <Modal onClose={unconfirmCancelOrder}>
            <div className="single-order-page__cancel__controls">
              {formattedError != null ? (
                <div className="single-order-page__error">
                  <Alert type="error" heading={formattedError.customerMessage}>
                    {formattedError.advice}
                  </Alert>
                </div>
              ) : null}

              <div className="single-order-page__modal-title">
                {isMasqueradingAfterCutoff
                  ? 'Are you sure you want to cancel the order after cutoff? This will refund the full order if it has been billed.'
                  : 'Are you sure you want to cancel your order?'}
              </div>
              <button
                type="button"
                className="single-order-page__cancel__cancel-button"
                onClick={unconfirmCancelOrder}
              >
                don{"'"}t cancel
              </button>
              <SubmitButton
                className="single-order-page__cancel__confirm-button"
                isSaving={isSaving}
                workingLabel="cancelling"
                onClick={isMasqueradingAfterCutoff ? saveCancelOrderAfterCutoff : saveCancelOrder}
              >
                cancel order
              </SubmitButton>
            </div>
          </Modal>
        ) : null}
      </div>
    );
  }
}

function mapStateToProps(
  state: State,
): Pick<Props, 'isConfirming' | 'isSaving' | 'formattedError'> {
  return {
    formattedError: formatError(state.cancelOrder.error),
    isConfirming: state.cancelOrder.isConfirming,
    isSaving: state.cancelOrder.isSaving,
  };
}

export default connect(mapStateToProps, {
  confirmCancelOrder: actions.confirmCancelOrder,
  unconfirmCancelOrder: actions.unconfirmCancelOrder,
  saveCancelOrder: actions.saveCancelOrder,
  saveCancelOrderAfterCutoff: actions.saveCancelOrderAfterCutoff,
})(CancelOrder);
