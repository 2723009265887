import _ from 'lodash';
import classNames from 'classnames';
import React, {Component} from 'react';
import {connect} from 'react-redux';

import {humanPriceFromCents} from 'infra/formatters/money';
import {defaultError} from 'infra/formatters/error';
import Masked from 'web/components/masked';
import Alert from 'web/components/alert';

import LineItem from './components/line_item';
import {actions} from '../../ducks/order_items';
import EditControls from '../edit_controls';

function formatError(state) {
  const {error} = state.orderItems;
  if (!error) {
    return null;
  }
  switch (error.type) {
    case 'PREDICTED_BELOW_MINIMUM': {
      const orderMinimum = humanPriceFromCents(error.details.orderMinimum, {short: true});
      return {
        customerMessage: `Sorry, can't save below a minimum subtotal of ${orderMinimum}`,
        advice: `Make sure your subtotal is above ${orderMinimum} and try again.`,
      };
    }
    case 'ALLOCATION_DENIED': {
      const details = error.details[0];
      switch (details ? details.error.type : null) {
        case 'PAST_CUTOFF':
          return {
            customerMessage: "It's too late to change this order",
            advice: 'The order cutoff has passed.',
          };
        default:
          return {
            customerMessage: 'Some products you wanted to add just sold out',
            advice: 'Reduce quantities or cancel to try again.',
          };
      }
    }
    default: {
      if (error.customerMessage) {
        return {
          customerMessage: error.customerMessage,
        };
      }
      return defaultError();
    }
  }
}

export function mapStateToProps(state) {
  const edits = state.orderItems.edits || {};
  return {
    items: state.order.items.map((item) => {
      let {quantity, subtotal, subtotalMinusSubscriptionDiscount} = item;
      const isChanged = item.id in edits;
      const maxQuantity = _.maxBy(
        item.product.quantityOptions,
        (option) => option.quantity,
      ).quantity;
      if (isChanged && edits[item.id].quantityDifference) {
        quantity += edits[item.id].quantityDifference;
        if (quantity) {
          subtotal = Math.round(
            item.product.quantityOptions.find(
              (quantityOption) => quantityOption.quantity === quantity,
            ).quantityPrice * 100,
          );
        } else {
          subtotal = 0;
        }
      }
      let isSubscribed = Boolean(item.subscriptionItemId);
      if (isChanged && edits[item.id].subscribe != null) {
        isSubscribed = edits[item.id].subscribe;
      }

      if (isSubscribed && quantity) {
        subtotalMinusSubscriptionDiscount = item.product.quantityOptions.find(
          (quantityOption) => quantityOption.quantity === quantity,
        ).quantitySubscriptionPrice;
      } else {
        subtotalMinusSubscriptionDiscount = subtotal;
      }
      return {
        ...item,
        isChanged,
        quantity,
        maxQuantity,
        subtotal,
        subtotalMinusSubscriptionDiscount,
        isSubscribed,
      };
    }),
    isEditable: state.order.isEditable,
    isEditing: state.orderItems.isEditing || false,
    isSaving: state.orderItems.isSaving,
    canSave: !_.isEmpty(state.orderItems.edits),
    formattedError: formatError(state),
  };
}

export class OrderItems extends Component {
  handleEdit = () => {
    this.props.dispatch(actions.startEditItems());
  };

  handleCancelEdit = () => {
    this.props.dispatch(actions.cancelEditItems());
  };

  handleSaveEdits = () => {
    this.props.dispatch(actions.saveItemEdits());
  };

  onRemoveItem = (itemId) => {
    this.props.dispatch(actions.removeItem(itemId));
  };

  onIncrementQuantity = (itemId) => {
    this.props.dispatch(actions.incrementItem(itemId));
  };

  onDecrementQuantity = (itemId) => {
    this.props.dispatch(actions.decrementItem(itemId));
  };

  onSetSubscribe = (item, subscribe) => {
    this.props.dispatch(actions.setSubscribe(item.id, subscribe));
  };

  renderOrderItems() {
    let editControls;
    if (this.props.isEditable) {
      editControls = (
        <EditControls
          isSaving={this.props.isSaving}
          isEditing={this.props.isEditing}
          canSave={this.props.canSave}
          onSave={this.handleSaveEdits}
          onCancel={this.handleCancelEdit}
          onChange={this.handleEdit}
        />
      );
    }

    let errors;
    if (this.props.formattedError) {
      const {customerMessage, advice} = this.props.formattedError;
      errors = (
        <div className="single-order-page__error">
          <Alert type="error" heading={customerMessage}>
            {advice}
          </Alert>
        </div>
      );
    }

    return (
      <div className={classNames('single-order-page__items', {editing: this.props.isEditing})}>
        <div className="single-order-page__items-header">
          {editControls}
          Items
        </div>
        {errors}
        <div className="single-order-page__items-grid">
          {this.props.items.map((item) => (
            <LineItem
              key={item.id}
              productId={item.product.id}
              canSubscribe={item.canSubscribe}
              subscriptionItemId={item.subscriptionItemId}
              isSubscribed={item.isSubscribed}
              subtotalMinusSubscriptionDiscount={item.subtotalMinusSubscriptionDiscount}
              name={item.product.name}
              quantity={item.quantity}
              maxQuantity={item.maxQuantity}
              photoUrl={item.product.photoUrl}
              vendor={item.product.vendor}
              retailUnits={item.product.retailUnits}
              url={item.product.url}
              subtotal={item.subtotal}
              isQuantityEditable={item.isQuantityEditable}
              hasDeposit={item.depositSubtotal > 0}
              isEditing={this.props.isEditing && !this.props.isSaving}
              isChanged={item.isChanged}
              onToggleSubscribe={_.partial(this.onSetSubscribe, item, _)}
              onDecrementQuantity={() => {
                this.onDecrementQuantity(item.id);
              }}
              onIncrementQuantity={() => {
                this.onIncrementQuantity(item.id);
              }}
              onRemoveItem={() => {
                this.onRemoveItem(item.id);
              }}
            />
          ))}
        </div>
        {this.props.isEditing ? (
          <div className="single-order-page__items-footer">{editControls}</div>
        ) : null}
      </div>
    );
  }

  render() {
    if (this.props.isEditing) {
      return <Masked>{this.renderOrderItems()}</Masked>;
    }
    return this.renderOrderItems();
  }
}

export default connect(mapStateToProps)(OrderItems);
