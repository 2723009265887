import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Checkbox from 'web/components/checkbox';
import {humanPriceFromCents} from 'infra/formatters/money';

const SubscribeControls = ({active, onToggle}) => (
  <div
    className="single-order-page__line-item__subscription-controls"
    onClick={() => onToggle(!active)}
  >
    <Checkbox active={active} />
    <i className="icon icon-subscriptions" />
    <span className="subscribe-label">Subscribe</span>
  </div>
);

SubscribeControls.propTypes = {
  active: PropTypes.bool,
  onToggle: PropTypes.func,
};

export default class LineItem extends Component {
  render() {
    let incrementButton;
    const canIncrementQuantity =
      this.props.isQuantityEditable && this.props.quantity < this.props.maxQuantity;
    if (this.props.isEditing) {
      incrementButton = (
        <div
          className={classNames('single-order-page__line-item-increment-quantity', {
            disabled: !canIncrementQuantity,
          })}
          onClick={this.props.onIncrementQuantity}
        >
          <i className="icon icon-chevron" />
        </div>
      );
    }

    let decrementButton;
    const canDecrementQuantity = this.props.isQuantityEditable && this.props.quantity > 0;
    if (this.props.isEditing) {
      decrementButton = (
        <div
          className={classNames('single-order-page__line-item-decrement-quantity', {
            disabled: !canDecrementQuantity,
          })}
          onClick={this.props.onDecrementQuantity}
        >
          <i className="icon icon-chevron" />
        </div>
      );
    }

    return (
      <div
        className={classNames('single-order-page__line-item', {
          empty: !this.props.quantity,
          changed: this.props.isChanged,
          editing: this.props.isEditing,
        })}
        data-integration-id={`${this.props.productId}|sub=${Boolean(
          this.props.subscriptionItemId,
        )}`}
      >
        <div className="single-order-page__line-item-quantity">
          {incrementButton}
          <div className="single-order-page__line-item-quantity-value">{this.props.quantity}</div>
          {decrementButton}
        </div>
        <div className="single-order-page__line-item-image">
          <div
            className="single-order-page__line-item-image-image"
            style={{backgroundImage: `url(${this.props.photoUrl})`}}
          />
        </div>
        <div className="single-order-page__line-item__info">
          <div className="single-order-page__line-item-details">
            <div className="single-order-page__line-item-details-vendor-name">
              <a href={this.props.vendor.url}>{this.props.vendor.name}</a>
            </div>
            <div className="single-order-page__line-item-details-name">
              <a href={this.props.url}>
                {this.props.hasDeposit ? `${this.props.name}*` : this.props.name}
              </a>
            </div>
            <div className="single-order-page__line-item-details-unit-quantity">
              {this.props.retailUnits}
            </div>
            <div className="single-order-page__line-item-details-total">
              {this.renderSubtotal()}
            </div>
            {this.props.isGift || !this.props.canSubscribe ? null : (
              <div className="single-order-page__line-item__subscription">
                {this.props.isEditing ? (
                  <SubscribeControls
                    active={Boolean(this.props.isSubscribed)}
                    onToggle={this.props.onToggleSubscribe.bind(this)}
                  />
                ) : !this.props.isSubscribed ? null : (
                  <div className="single-order-page__line-item__subscribed">
                    <i className="icon icon-subscriptions" />
                    Subscribed
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="single-order-page__line-item__controls">
            <div className="single-order-page__line-item__controls-left">
              <div className="single-order-page__line-item__total">{this.renderSubtotal()}</div>
            </div>
            <div className="single-order-page__line-item__remove">
              {this.props.isEditing && this.props.quantity !== 0 && (
                <>
                  <div
                    className="single-order-page__line-item-x-button icon icon-thin-x"
                    onClick={this.props.onRemoveItem}
                  />
                  <div
                    className="single-order-page__line-item__remove-link"
                    onClick={this.props.onRemoveItem}
                  >
                    Remove
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderSubtotal() {
    if (
      this.props.subtotalMinusSubscriptionDiscount &&
      this.props.subtotalMinusSubscriptionDiscount !== this.props.subtotal
    ) {
      return (
        <div className="summary-item__prices">
          <div className="summary-item__strikethrough-price">
            {humanPriceFromCents(this.props.subtotal)}
          </div>
          <div className="summary-item__price">
            {humanPriceFromCents(this.props.subtotalMinusSubscriptionDiscount)}
          </div>
        </div>
      );
    }
    return (
      <div className="summary-item__prices">
        <div className="summary-item__price">
          {this.props.quantity === 0
            ? humanPriceFromCents(this.props.subtotal)
            : humanPriceFromCents(this.props.subtotal, {showZeroAsFree: true})}
        </div>
      </div>
    );
  }
}
