import React, {Component} from 'react';
import classNames from 'classnames';

import SubmitButton from 'web/components/submit_button';

export default class EditControls extends Component {
  render() {
    return (
      <div className="single-order-page__edit-controls">
        {this.props.isEditing ? (
          <span>
            <SubmitButton
              className="single-order-page__save-button"
              isSaving={this.props.isSaving}
              workingLabel="saving"
              disabled={this.props.hasChanges && !this.props.canSave}
              onClick={this.props.canSave ? this.props.onSave : this.props.onCancel}
            >
              save
            </SubmitButton>
            <button
              className={classNames('single-order-page__cancel-button', {
                disabled: this.props.isSaving,
              })}
              disabled={this.props.isSaving}
              onClick={this.props.onCancel}
            >
              cancel
            </button>
          </span>
        ) : (
          <button className="single-order-page__change-button" onClick={this.props.onChange}>
            change
          </button>
        )}
      </div>
    );
  }
}
