import React from 'react';
import {Field, Fields, reduxForm, formValueSelector} from 'redux-form';
import {formatPhone} from 'goodeggs-formatters';
import {email as validateEmail} from 'goodeggs-validators';
import {connect} from 'react-redux';
import {bool, object} from 'prop-types';
import rolodex from 'goodeggs-rolodex';

import {defaultError} from 'infra/formatters/error';
import validatePhone from 'infra/validators/phone';
import Alert from 'web/components/alert';
import ToggleButton from 'web/components/toggle_button';

import {
  AddressAutoCompleteInput,
  GiftRecipientEmailInput,
  GiftRecipientPhoneInput,
  CanLeaveAtDoorInput,
  InputWithErrors,
} from './inputs';

function formatError(error) {
  if (!error) {
    return null;
  }
  switch (error.type) {
    case 'EMAIL_USED':
      return {
        customerMessage: 'That email has already been registered',
      };
    case 'FULFILLMENT_CUTOFF_PASSED':
      return {
        customerMessage: "Oops... it's too late change this order",
        advice: 'The order cutoff has passed.',
      };
    case 'FULFILLMENT_NOT_EDITABLE':
      return {
        customerMessage: 'Oops... this order is no longer editable',
        advice: 'Please refresh this page.',
      };
    case 'INVALID_PHONE_NUMBER':
      return {
        customerMessage: 'That phone number doesn’t look quite right',
      };
    case 'UNAVAILABLE_ZIPCODE':
      return {
        customerMessage: 'It looks like this address is outside of our delivery area.',
        advice: (
          <a
            href="https://www.goodeggs.com/how-it-works#where-we-deliver"
            target="_blank"
            rel="noopener"
          >
            See our current delivery areas.
          </a>
        ),
      };
    case 'ATTENDED_DELIVERY_REQUIRED':
      return {
        customerMessage: 'Attended delivery is required',
        advice: 'ID required in person for alcohol delivery.',
      };
    case 'PRODUCTS_UNAVAILABLE_IN_NEW_DELIVERY':
      return {
        customerMessage:
          'Changing your delivery address affects the availability of items in your order. To guarantee accurate item availability, please start a new order with your updated address.',
      };
    case 'FULFILLMENT_OFFER_NOT_FOUND':
    case 'FULFILLMENT_OFFER_SOLD_OUT':
    case 'FULFILLMENT_OFFER_CLOSED':
    case 'FULFILLMENT_OFFER_UNAVAILABLE':
      return {
        customerMessage:
          'Sorry, the selected delivery time is not available for this address. Try choosing another time.',
        advice: (
          <span>
            <a target="_blank" rel="noopener" href={`mailto:${rolodex.eaterHelpEmailAddress()}`}>
              Email{' '}
            </a>
            or call community care {formatPhone(rolodex.eaterHelpPhoneNumber())} if you need help.
          </span>
        ),
      };
    default:
      return defaultError();
  }
}

export function DeliveryAddressFormError(props) {
  const {customerMessage, advice} = formatError(props.error);
  return (
    <div className="delivery-address-form__edit-error">
      <Alert type="error" heading={customerMessage}>
        {advice}
      </Alert>
    </div>
  );
}

DeliveryAddressFormError.propTypes = {
  error: object,
};

export function validateDeliveryAddress(values) {
  const errors = {};
  const recipientName = values.giftRecipientName?.trim();

  if (values.isGift && !values.giftRecipientName) {
    errors.giftRecipientName = 'Please enter a name';
  }
  if (values.isGift && recipientName?.split(' ').length === 1) {
    errors.giftRecipientName = 'Must contain a first and last name';
  }
  if (values.isGift && (!values.giftRecipientPhone || !validatePhone(values.giftRecipientPhone))) {
    errors.giftRecipientPhone = 'Invalid phone';
  }
  if (
    values.isGift &&
    values.giftRecipientEmail != null &&
    values.giftRecipientEmail.trim() !== '' &&
    !validateEmail(values.giftRecipientEmail)
  ) {
    errors.giftRecipientEmail = 'Invalid email';
  }

  const missingFields = [];
  if (!values.address) {
    missingFields.push('Street number and name');
  }
  if (!values.city) {
    missingFields.push('City');
  }
  if (!values.state) {
    missingFields.push('State');
  }
  if (!values.zip) {
    missingFields.push('Zip code');
  }
  if (missingFields.length > 0) {
    errors.address = `Missing: ${missingFields.join(', ')}`;
  }

  return errors;
}

const DeliveryAddressForm = ({apiError, showGiftToggle, isGift, isAttendedDeliveryRequired}) => (
  <form className="delivery-address-form">
    {apiError && <DeliveryAddressFormError error={apiError} />}
    {showGiftToggle && (
      <div className="delivery-address-form__line-item">
        <div className="delivery-address-form__is-gift">
          <div className="delivery-address-form__inline-header">Is this a gift delivery?</div>
          <Field name="isGift" component={ToggleButton} format={null} />
        </div>
      </div>
    )}
    {isGift && (
      <div className="delivery-address-form__line-item">
        <div className="delivery-address-form__gift-recipient-column">
          <div className="delivery-address-form__edit-header">Recipient First and Last Name</div>
          <Field
            name="giftRecipientName"
            className="form-control"
            placeholder="Recipient's First and Last Name"
            component={InputWithErrors}
          />
        </div>
      </div>
    )}
    <div className="delivery-address-form__line-item">
      <div className="delivery-address-form__street-address-column">
        <div className="delivery-address-form__edit-header">Street Address</div>
        <Fields names={['address', 'city', 'state', 'zip']} component={AddressAutoCompleteInput} />
      </div>
      <div className="delivery-address-form__address2-column">
        <div className="delivery-address-form__edit-header">Apt/Unit</div>
        <Field name="address2" className="form-control" placeholder="Apt/Unit" component="input" />
      </div>
    </div>
    {isGift && (
      <div className="delivery-address-form__line-item">
        <div className="delivery-address-form__gift-recipient-phone-column">
          <div className="delivery-address-form__edit-header">Recipient Phone</div>
          <Field name="giftRecipientPhone" component={GiftRecipientPhoneInput} />
        </div>
      </div>
    )}
    {isGift && (
      <div className="delivery-address-form__line-item">
        <div className="delivery-address-form__gift-recipient-email-column">
          <div className="delivery-address-form__edit-header">Recipient Email</div>
          <Field name="giftRecipientEmail" component={GiftRecipientEmailInput} />
        </div>
      </div>
    )}
    <div className="delivery-address-form__leave-at-door">
      <div className="delivery-address-form__edit-header">Delivery Options</div>
      <Field
        name="canLeaveAtDoor"
        isAttendedDeliveryRequired={isAttendedDeliveryRequired}
        format={null}
        component={CanLeaveAtDoorInput}
      />
    </div>
    <div className="delivery-address-form__delivery-instructions">
      <div className="delivery-address-form__edit-header">Delivery Instructions (Optional)</div>
      <Field
        name="deliveryInstructions"
        component="textarea"
        className="form-control"
        placeholder="Buzzer/gate code, Leave on porch ..."
      />
    </div>
    {isGift && (
      <div className="delivery-address-form__gift-message">
        <div className="delivery-address-form__edit-header">Gift Message</div>
        <Field
          className="form-control"
          name="giftMessage"
          placeholder="Please enter a gift message"
          component="textarea"
        />
      </div>
    )}
  </form>
);

DeliveryAddressForm.propTypes = {
  showGiftToggle: bool,
  isGift: bool,
  apiError: object,
  isAttendedDeliveryRequired: bool,
};

const DeliveryAddressReduxForm = reduxForm({
  form: 'deliveryAddress',
  validate: validateDeliveryAddress,
  touchOnBlur: true,
})(DeliveryAddressForm);

const selector = formValueSelector('deliveryAddress');

function mapStateToProps(state, props) {
  return {
    isGift: Boolean(props.showGiftToggle ? selector(state, 'isGift') : props.isGift),
  };
}
export default connect(mapStateToProps)(DeliveryAddressReduxForm);
