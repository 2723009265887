import _ from 'lodash';
import React, {Component} from 'react';
import {connect} from 'react-redux';

import moment from 'infra/moment';
import {humanDayOfWeek, deliveryTimeLabel, humanTimeRangeChoice} from 'infra/formatters/time';
import {defaultError} from 'infra/formatters/error';
import ChoiceSelect from 'web/components/choice_select';
import Masked from 'web/components/masked';
import Alert from 'web/components/alert';
import {transformOfferForTracking} from 'web/helpers/serializers/transform_offer_for_tracking';

import EditControls from './edit_controls';
import {actions} from '../ducks/delivery_time';

function formatError(state) {
  const {error} = state.deliveryTime;
  if (!error) {
    return null;
  }
  switch (error.type) {
    case 'FULFILLMENT_CUTOFF_PASSED':
      return {
        customerMessage: "Oops... it's too late change this order",
        advice: 'The order cutoff has passed.',
      };
    case 'FULFILLMENT_NOT_EDITABLE':
      return {
        customerMessage: 'Oops... this order is no longer editable',
        advice: 'Please refresh this page.',
      };
    case 'FULFILLMENT_OFFER_SOLD_OUT':
      return {
        customerMessage: 'Oops... that delivery time has sold out.',
        advice: 'Please try a different time.',
      };
    case 'FULFILLMENT_OFFER_UNAVAILABLE':
      return {
        customerMessage: 'Oops... that delivery time is not available in your area.',
        advice: 'Please try a different time.',
      };
    case 'DELIVERY_RESTRICTED':
      return {
        customerMessage: 'Oops... one of your items is not available for that delivery window.',
        advice: 'Please try a different time.',
      };
    default:
      return defaultError();
  }
}

export function mapStateToProps(state) {
  return {
    tzid: state.settings.tzid,
    isEditable: state.order.isEditable,
    isEditing: state.deliveryTime.isEditing,
    isSaving: state.deliveryTime.isSaving,
    canSave: !_.isEqual(
      _.pick(state.deliveryTime.selectedFulfillmentOffer, 'startAt', 'endAt'),
      state.order.deliverywindow,
    ),
    formattedError: formatError(state),
    deliveryWindow: {
      startAt: moment.tz(state.order.deliveryWindow.startAt, state.settings.tzid),
      endAt: moment.tz(state.order.deliveryWindow.endAt, state.settings.tzid),
    },
    hasChanged: !_.isMatch(
      state.deliveryTime.selectedFulfillmentOffer || {},
      state.order.deliveryWindow,
    ),
    selectedFulfillmentOffer: state.deliveryTime.selectedFulfillmentOffer,
    fulfillmentOptions: (state.fulfillmentOptions || []).map((option) =>
      _.assignIn({}, option, {
        offer: _.assignIn({}, option.offer, {
          startAt: moment.tz(option.offer.startAt, state.settings.tzid),
          endAt: moment.tz(option.offer.endAt, state.settings.tzid),
        }),
      }),
    ),
  };
}

function timeOfDay(m) {
  return 60 * m.hours() + m.minutes();
}

export class DeliveryTime extends Component {
  // Returns an array of groups
  groupFulfillmentOptions(fulfillmentOptions) {
    return _.chain(fulfillmentOptions)
      .groupBy('pricingDescription')
      .toPairs()
      .map(([pricingDescription, options]) => ({
        name: pricingDescription,
        options: _.sortBy(options, (option) => timeOfDay(option.offer.startAt)),
      }))
      .sortBy((group) => group.options[0].price)
      .value();
  }

  handleChangeClicked() {
    this.props.dispatch(actions.startEditTime());
  }

  handleCancelClicked() {
    this.props.dispatch(actions.cancelEditTime());
  }

  handleSaveClicked() {
    if (!this.props.hasChanged) {
      return this.props.dispatch(actions.cancelEditTime());
    }
    this.props.dispatch(actions.saveEditTime());
  }

  handleFulfillmentOptionSelected(event) {
    const offer = JSON.parse(event.target.value);
    this.props.dispatch(actions.selectTime(offer));
    this.handleWindowSelected(offer);
  }

  handleWindowSelected(offerInput) {
    if (this.props.fulfillmentOptions == null) return;

    const selectedOffer = this.props.fulfillmentOptions.find(({offer: {startAt}}) => {
      return moment(startAt).isSame(offerInput.startAt);
    });

    if (selectedOffer == null) return;

    window.metrics.track('Delivery Window Selected', {
      windowChosen: transformOfferForTracking({tzid: this.props.tzid, offer: selectedOffer}),
    });
  }

  handleOptionsViewed() {
    if (this.props.fulfillmentOptions == null) return;

    const selectedOffer = this.props.fulfillmentOptions.find(({offer: {startAt}}) => {
      return moment(startAt).isSame(this.props.selectedFulfillmentOffer?.startAt);
    });

    window.metrics.track('Delivery Window Options Viewed', {
      windowsViewed: this.props.fulfillmentOptions.map((offer) =>
        transformOfferForTracking({tzid: this.props.tzid, offer}),
      ),
      windowPreviouslyChosen: selectedOffer
        ? transformOfferForTracking({
            tzid: this.props.tzid,
            offer: selectedOffer,
          })
        : undefined,
    });
  }

  renderDeliveryTime() {
    const {startAt, endAt} = this.props.deliveryWindow;
    const optionsInGroups = this.groupFulfillmentOptions(this.props.fulfillmentOptions);

    let errors;
    if (this.props.formattedError) {
      const {customerMessage, advice} = this.props.formattedError;
      errors = (
        <div className="single-order-page__delivery-time-error">
          <Alert type="error" heading={customerMessage}>
            {advice}
          </Alert>
        </div>
      );
    }

    return (
      <div className="single-order-page__section single-order-page__delivery-time">
        {this.props.isEditable ? (
          <EditControls
            isEditing={this.props.isEditing}
            isSaving={this.props.isSaving}
            canSave={this.props.canSave}
            onChange={this.handleChangeClicked.bind(this)}
            onSave={this.handleSaveClicked.bind(this)}
            onCancel={this.handleCancelClicked.bind(this)}
          />
        ) : null}
        <div className="single-order-page__section-heading">Delivery Time</div>
        {errors}
        {this.props.isEditing ? (
          <div className="single-order-page__delivery-time-body">
            <div className="single-order-page__delivery-time-block">
              <div className="single-order-page__delivery-time-label">Date</div>
              <div>{humanDayOfWeek(startAt, startAt.tz())}</div>
            </div>
            <div className="single-order-page__delivery-time-block">
              <div className="single-order-page__delivery-time-label select-offset">Time</div>
              <ChoiceSelect
                className="single-order-page__delivery-time-select"
                value={JSON.stringify(this.props.selectedFulfillmentOffer)}
                disabled={this.props.isSaving}
                onChange={this.handleFulfillmentOptionSelected.bind(this)}
                onClick={this.handleOptionsViewed.bind(this)}
              >
                {optionsInGroups.map((group) => (
                  <optgroup label={group.name} key={group.name}>
                    {group.options.map((option, index) => (
                      <option key={index} value={JSON.stringify(option.offer)}>
                        {humanTimeRangeChoice(
                          option.offer.startAt,
                          option.offer.endAt,
                          this.props.tzid,
                        )}
                      </option>
                    ))}
                  </optgroup>
                ))}
              </ChoiceSelect>
            </div>
          </div>
        ) : (
          <div className="single-order-page__delivery-time-body">
            {deliveryTimeLabel(startAt, endAt, startAt.tz())}
          </div>
        )}
      </div>
    );
  }

  render() {
    if (this.props.isEditing) {
      return <Masked>{this.renderDeliveryTime()}</Masked>;
    }
    return this.renderDeliveryTime();
  }
}

export default connect(mapStateToProps)(DeliveryTime);
