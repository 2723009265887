import {actions as marketLayoutActions} from 'web/components/market_layout/actions';

import {postCancelOrder} from '../helpers/api_client';

const ERROR_CANCELLING_ORDER = 'ORDER_ERROR_CANCELLING_ORDER';
const CANCELLING_ORDER = 'ORDER_CANCELLING_ORDER';
const CONFIRM_CANCEL_ORDER = 'CONFIRM_CANCEL_ORDER';
const UNCONFIRM_CANCEL_ORDER = 'UNCONFIRM_CANCEL_ORDER';

export const actions = {
  confirmCancelOrder: () => (dispatch) => {
    dispatch(marketLayoutActions.disableScrolling());
    dispatch({type: CONFIRM_CANCEL_ORDER});
  },
  unconfirmCancelOrder: () => (dispatch) => {
    dispatch(marketLayoutActions.enableScrolling());
    dispatch({type: UNCONFIRM_CANCEL_ORDER});
  },
  saveCancelOrder: () => (dispatch, getState) => {
    dispatch({type: CANCELLING_ORDER});
    const {order, user} = getState();
    return postCancelOrder(order.id, {afterCutoffOverride: false})
      .then(() => {
        window.metrics.track('Cancelled Order', {orderId: order.id, userId: user.id});
        window.location = `/account/orders?alert=cancel-success&orderId=${order.id}`;
      })
      .catch((error) => dispatch({type: ERROR_CANCELLING_ORDER, error}));
  },
  saveCancelOrderAfterCutoff: () => (dispatch, getState) => {
    dispatch({type: CANCELLING_ORDER});
    const {order} = getState();
    return postCancelOrder(order.id, {afterCutoffOverride: true})
      .then(() => {
        window.location = `/account/orders?alert=cancel-success&orderId=${order.id}`;
      })
      .catch((error) => dispatch({type: ERROR_CANCELLING_ORDER, error}));
  },
};

// TODO(serhalp) When defining a type for this State, import it in `../components/cancel_order` and
// remove the State type defined there.
export function reducer(state = {}, action = {}) {
  const cancelOrder = state.cancelOrder || {};
  let {error} = cancelOrder;
  let isConfirming = cancelOrder.isConfirming || false;
  let isSaving = cancelOrder.isSaving || false;

  switch (action.type) {
    case CONFIRM_CANCEL_ORDER:
      isConfirming = true;
      break;
    case UNCONFIRM_CANCEL_ORDER:
      isConfirming = false;
      error = null;
      break;
    case ERROR_CANCELLING_ORDER:
      error = action.error;
      isSaving = false;
      break;
    case CANCELLING_ORDER:
      error = null;
      isSaving = true;
      break;
    default:
    // noop
  }

  return {
    ...state,
    cancelOrder: {
      error,
      isConfirming,
      isSaving,
    },
  };
}
